.teamSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  perspective: 1000px;
}

.teamMember {
  width: 220px;
  height: 360px;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.teamMember:hover .cardInner {
  transform: rotateY(180deg);
}

.cardFront, .cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.cardFront {
  background-color: #f9f9f9;
  z-index: 2;
}

.cardBack {
  background-color: #f9f9f9; /* Same as cardFront */
  color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* To contain the blurred logo */
}

.backLogo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.1; /* Adjust for desired intensity */
  filter: blur(80%); /* Adjust blur amount as needed */
}

.quote {
  font-style: italic;
  font-size: 1em;
  line-height: 1.4;
  font-family: var(--font-kode-mono);
  padding: 2%;
  position: relative;
  z-index: 1; /* Ensure quote is above the blurred logo */
  color: #333; /* Adjust color for better readability */
}

.imageContainer {
  width: 120px;
  height: 120px;
  position: relative;
  margin-bottom: 15px;
  /* Remove overflow: hidden from here */
}

.memberImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%; /* Move border-radius here */
}

.statusIndicator {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #fff;
  z-index: 3; /* Add this to bring it to the front */
}

.active {
  background-color: #4CAF50;
}

.retired {
  background-color: #9E9E9E;
}

.status {
  font-size: 0.9em;
  font-weight: bold;
  color: #555;
}