.contactForm {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Add this to include padding in the width calculation */
}

.contactForm input,
.contactForm textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  background-color: #f9f9f9;
  box-sizing: border-box; /* Add this to include padding in the width calculation */
}

.contactForm textarea {
  height: 150px;
  resize: vertical;
}

.contactForm button {
  font-family: var(--font-ubuntu);
  font-weight: 700;
  background-color: #458b74;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.contactForm button:hover {
  background-color: #3a7563; /* Slightly darker shade for hover */
}

/* Media queries for responsiveness */
@media (max-width: 840px) {
  .contactForm {
    width: 95%;
  }
}

@media (max-width: 600px) {
  .contactForm {
    width: 100%;
    padding: 15px;
    border-radius: 0; /* Remove border-radius on very small screens */
  }
}