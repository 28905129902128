.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #212721;
  padding: 10px 0;
  box-shadow: 
    0 -2px 4px rgba(0,0,0,0.1),
    inset 0 1px 3px rgba(255,255,255,0.1),
    0 -1px 0 rgba(255,255,255,0.05);
  z-index: 2;
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  color: #f0f0f0; /* Light text color for better contrast */
}

.footerContent::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, 
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.1) 50%,
    rgba(255,255,255,0) 100%
  );
}