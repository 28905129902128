.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #212721; /* Dark background color, same as footer */
  padding: 10px 0;
  box-shadow: 
    0 2px 4px rgba(0,0,0,0.1),
    inset 0 -1px 3px rgba(255,255,255,0.1),
    0 1px 0 rgba(255,255,255,0.05);
  z-index: 1000;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.title {
  color: #f0f0f0; /* Light text color for better contrast */
  margin: 0;
  font-size: 24px;
}

.nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav li {
  margin-left: 20px;
}

.nav a {
  text-decoration: none;
  color: #f0f0f0; /* Light text color for better contrast */
}

.nav a:hover {
  text-decoration: underline;
}

.headerContent::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, 
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.1) 50%,
    rgba(255,255,255,0) 100%
  );
}