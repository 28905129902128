@font-face {
    font-family: 'Kode Mono';
    src: url('./assets/fonts/Kode_Mono/KodeMono-VariableFont_wght.ttf') format('truetype-variations');
    font-weight: 100 900;  
    font-stretch: 75% 125%;  
    font-style: normal;
  }
  
@font-face {
    font-family: 'New Amsterdam';
    src: url('./assets/fonts/New_Amsterdam/NewAmsterdam-Regular.ttf') format('truetype-variations');
    font-weight: 100 900;  
    font-stretch: 75% 125%;  
    font-style: normal;
  }
  
@font-face {
    font-family: 'Ubuntu';
    src: url('./assets/fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype-variations');
    font-weight: 100 900;  
    font-stretch: 75% 125%;  
    font-style: normal;
  }

@font-face {
    font-family: 'Kode Mono';
    src: url('./assets/fonts/Work_Sans/WorkSans-VariableFont_wght.ttf') format('truetype-variations');
    font-weight: 100 900;  
    font-stretch: 75% 125%;  
    font-style: normal;
  }

:root {
    --font-kode-mono: 'Kode Mono', monospace;
    --font-new-ams: 'New Amsterdam', monospace;
    --font-ubuntu: 'Ubuntu';
    --font-kode-mono: 'Kode Mono', monospace;
  }
  
  