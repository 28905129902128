@import './fonts.css';  /* Make sure this points to your font declarations */

:root {
  --puma-base: #faebd7;
  --puma-spot1: #d2b48c;
  --puma-spot2: #cd853f;
  --puma-spot3: #deb887;
}

body {
  font-family: var(--font-kode-mono);
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: var(--puma-base);
  position: relative;
}

body::before,
body::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

body::before {
  background-image: 
    radial-gradient(ellipse at center, var(--puma-spot1) 0%, transparent 70%),
    radial-gradient(circle at center, var(--puma-spot2) 0%, transparent 60%),
    radial-gradient(ellipse at center, var(--puma-spot3) 0%, transparent 50%);
  background-size: 
    calc(12vw + 50px) calc(10vw + 50px),
    calc(8vw + 30px) calc(8vw + 30px),
    calc(15vw + 70px) calc(13vw + 70px);
  background-position: 
    calc(50% - 15vw) calc(50% - 10vh),
    calc(50% + 20vw) calc(50% + 15vh),
    calc(50% - 5vw) calc(50% + 20vh);
  opacity: 0.25;
  filter: blur(1px);
}

body::after {
  background-image: 
    radial-gradient(ellipse at center, var(--puma-spot2) 0%, transparent 65%),
    radial-gradient(circle at center, var(--puma-spot1) 0%, transparent 55%),
    radial-gradient(ellipse at center, var(--puma-spot3) 0%, transparent 45%);
  background-size: 
    calc(10vw + 40px) calc(9vw + 40px),
    calc(7vw + 25px) calc(7vw + 25px),
    calc(13vw + 60px) calc(11vw + 60px);
  background-position: 
    calc(50% + 18vw) calc(50% - 18vh),
    calc(50% - 22vw) calc(50% + 12vh),
    calc(50% + 8vw) calc(50% - 5vh);
  opacity: 0.12;
  filter: blur(0.5px);
  transform: rotate(-10deg);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-new-ams);
  font-weight: 700;  /* Bold */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 10%;
  background-color: rgba(250, 235, 215, 0.85);  /* Slightly more transparent */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

button {
  font-family: var(--font-ubuntu);
  font-weight: 700;  /* Bold */
  background-color: #458b74;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
}

input, textarea {
  font-family: var(--font-kode-mono);
  font-weight: 400;  /* Regular */
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

.news-list {
  max-width: 800px;
  margin: 0 auto;
}

.news-item {
  background-color: rgba(249, 249, 249, 0.9);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

.news-item h3 {
  margin-top: 0;
  color: #333;
}

.news-item p {
  color: #666;
}

.news-item small {
  display: block;
  color: #999;
  margin-top: 10px;
}

section {
  margin-bottom: 40px; 
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

/* Ensure content doesn't touch the edges on smaller screens */
@media (max-width: 768px) {
  main {
    padding: 15px;
  }
}